<template>
  <container style="max-width: 1000px;">
    <v-row>
        <v-col>
            <v-card outlined>
                <v-toolbar elevation="0">
                    <v-btn @click="$router.go(-1)" icon>
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-toolbar-title class="primary--text">
                      {{ __('Importar nova lead', 'new_lead') }}
                    </v-toolbar-title>
                </v-toolbar>
            </v-card>
        </v-col>
    </v-row>
    
    <router-view></router-view>
  </container>
</template>

<script>
import i18n from '@/mixins/i18n';

export default {
  mixins: [i18n],
}
</script>

<style>

</style>